
import { inject } from '@/inversify';
import { Component, Mixins } from 'vue-property-decorator';
import { KEY } from '@/inversify.keys';

import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import type ProvidersService from '@/modules/providers/providers.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class DownloadMappingPopup extends Mixins(DownloadReportControlMixin) {
    @inject(RoomsTypeManagerServiceS) private roomsTypeManagerService!: RoomsTypeManagerService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    public form: { [key: string]: string } = {};
    private isLoading = false;

    get attrs() {
        const { filters } = this;
        const { buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            buttons,
            isLoading,
            dataType: this.$tc('settings.room-type-mapping.title'),
        };
    }

    private get isDownloadDisabled() {
        const { provider } = this.form;

        if (!provider) {
            return true;
        }

        return false;
    }

    private get buttons() {
        const buttons = [
            {
                label: this.$tc('popup.download'),
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
        ];

        return buttons;
    }

    private get filters() {
        const filters = [
            {
                label: this.$tc('titles.provider'),
                key: 'provider',
                options: this.roomsTypeManagerService.providers
                    .map(provider => ({
                        value: provider,
                        name: this.providersService.allProviders[provider].label,
                    })) as { name: string, value: any }[],
                default: this.roomsTypeManagerService.providers[0],
            },
        ];

        return filters;
    }

    private async downloadExcel() {
        try {
            this.isLoading = true;
            const { form } = this;

            await this.roomsTypeManagerService.downloadExcel(Number(this.$route.params.hotelId), form.provider);
            this.close();
        } catch (e) {
            throw e;
        } finally {
            this.isLoading = false;
        }
    }

    close() {
        const { form } = this.$refs as { form: DownloadReportForm };
        if (!form) return;
        form.close();
    }
}
